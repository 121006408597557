import { popoverAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

export const getPopoverTheme = () => {
    const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
        popoverAnatomy.keys,
    );

    return defineMultiStyleConfig({
        baseStyle: {
            content: {
                bg: "dark_purple",
                color: "white",
                border: "none",
                rounded: "xl",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            },
            arrow: {
                bg: "dark_purple !important",
                shadow: "0px 0px 0px rgba(0, 0, 0, 0.25) !important",
            },
        },
    });
};
